import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import PillButton from './components/PillButton';

const iconStyle = {
    width: "4rem",
    padding: 1,
}

const skillStyle = {
    margin: 10,
}

const downloadIconStyle = {
    width: "1.5rem",
    padding: 1,
}

const firstImageStyle = {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
}

const imageStyle = {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 400,
    height: 400,
};

const MainPage = ({ isMobile }) => {

    return ( 
        <Fragment>
        <br/>
        <Container  fluid>
            <Row>
                <Col>
                    <h1 style={{ fontSize: isMobile ? "2rem" : "3rem"  }} id="title-name">JESSICA GONZALEZ</h1>
                </Col>
            </Row>
            <br/>
            <br/>
            <Row>
                <Col lg={6}>
                    <Image style={{...firstImageStyle, height: isMobile ? 400 : 500, borderRadius: 10 }} src="images/First.jpeg" alt="image" fluid />
                    <br/>
                </Col>
                <Col lg={6}>
                    <p style={{fontSize: isMobile ? "1.25rem" : "2rem", marginTop: isMobile ? 15 : 0, textAlign: "left" }} className="mellow-banner-text"> 
                    <div className="bouncing-text">Hi there!</div>
                    <br/>
                    I am a Senior Financial Analyst passionate about data analysis, process improvement, and automation. With more than 8 years of experience in Accounting and Finance, I have built a strong foundation for insightful analysis and decision-making. My goal is to make financial data accessible and engaging to all my teammates who need it! 
                    </p>
                </Col>
            </Row>
            <br/>
            <br/>
            <br/>
            <Row>
                <Col lg={12}>
                    <PillButton variant="primary" downloadName="Jessica_Gonzalez_Resume" downloadLink="/resume/Jessica_Gonzalez_Resume.pdf">
                        View Resume
                    </PillButton>
                </Col>
            </Row>
            <br/>
            <br/>

            <Row style={isMobile ? { marginLeft: 10, marginRight: 10 } : { marginLeft: 50, marginRight: 50 }}>
                <Col lg={4}>
                    <Image style={{...imageStyle, borderRadius: 10 }} src="images/Main.jpeg" alt="image" fluid />
                    <div>
                        <br/>
                        <h3>Work</h3>
                        <hr/>
                        <ul>
                            <li style={{textAlign: "left"}}>Financial Planning & Analysis</li>
                            <li style={{textAlign: "left"}}>Budgeting & Forecasting</li>
                            <li style={{textAlign: "left"}}>Financial Modeling</li>
                            <li style={{textAlign: "left"}}>Data Visualization</li>
                            <li style={{textAlign: "left"}}>Process Improvement & Automation</li>
                            <li style={{textAlign: "left"}}>Team Building</li>
                            <li style={{textAlign: "left"}}>Excellent Communication (in Spanish, too!)</li>
                        </ul>
                        <br/>
                    </div>
                </Col>
                <Col lg={4}>
                    <Image style={{...imageStyle, borderRadius: 10 }} src="images/Study.jpeg" alt="image" fluid />
                    <div>
                        <br/>
                        <h3>Study</h3>
                        <hr/>
                        <p>
                            I have a Bachelor’s in Business Administration from Florida International University, which has given me foundational knowledge in Accounting, Finance, and Business. Additionally, I have completed multiple certifications in Advanced Excel, Data Visualization, Budgeting, and SQL. Always looking to expand my knowledge and learn new skills!
                            <br/>
                        
                        </p>
                        <br/>
                    </div>
                </Col>
                <Col lg={4}>
                    <Image style={{...imageStyle, borderRadius: 10 }} src="images/Relax.jpg" alt="image" fluid />
                    <div>
                        <br/>
                        <h3>Relax</h3>
                        <hr/>
                        <p>
                            To balance things out, in my free time, I love to bake, read psychology books, and go on hikes to explore beautiful Colorado! 
                        </p>
                        <br/>
                    </div>
                </Col>
            </Row>
            <br/>
            <br/>
            <h5 style={{ fontSize: isMobile ? "1.5rem" : "2rem" }}> Projects </h5>
            <hr/>
            <Row>
                <Col lg={6}>
                    <h4 style={{ marginTop: 15 }}>Project Lookup Tool</h4>
                    <p>This is a lookup tool for "Project_IDs" and "Project_Names" to obtain information for projects quickly. (Only compatible with Recent Versions of Microsoft Office 365 on Windows)</p>
                    <Image style={{...imageStyle, borderRadius: 10, marginBottom: 10 }} src="images/lookup.gif" alt="image" fluid />
                    <br/>
                    <PillButton variant="primary" downloadName="LookupTool"  style={{ fontSize: 16 }}  downloadLink="/assets/ProjectNo_Name_Lookup_Tool.xlsx">
                        <img style={{...downloadIconStyle}} src="/images/download.svg" alt="download"/>
                    </PillButton>
                </Col>
                <Col lg={6}>
                    <h4 style={{ marginTop: 15 }}>Budget Modeling Exercise</h4>
                    <p>This is a financial model/template that calculates the expected spend for a hypothetical building company.</p>
                    <Image style={{...imageStyle, borderRadius: 10, marginBottom: 10 }} src="images/model.gif" alt="image" fluid />
                    <br/>
                    <PillButton variant="primary" downloadName="BudgetModeling" style={{ fontSize: 16 }} downloadLink="/assets/Budget_Modeling_Exercise.xlsx">
                        <img style={{ ...downloadIconStyle }}  src="/images/download.svg" alt="download"/>
                    </PillButton>
                </Col>
            </Row>
            <br/>
            <br/>

            <br/>
            <br/>
            <h5 style={{ fontSize: isMobile ? "1.5rem" : "2rem" }}> Technical Skills </h5>
            <hr/>
            <Row>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/excel.png" alt="excel"/>
                    Microsoft Excel
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/google-sheets.png" alt="google-sheets"/>
                    Google Sheets
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/mysql.png" alt="mysql"/>
                    MySQL
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/sap.png" alt="SAP"/>
                    SAP
                </Col>
            </Row>
            <br/>
            <br/>
            <Row>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/oracle.png" alt="Oracle"/>
                    Oracle
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/pbi.svg" alt="pbi"/>
                    Power BI
                    {/* <p>akjdbahjbdsahbd kjsb kjasbdk jabdk jabsk jdbaskdj bajd</p> */}
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/qlik.svg" alt="qlik"/>
                    Qlikview
                </Col>
                <Col xs={3} sm={3} lg={3}>
                    <img style={{...iconStyle, ...skillStyle }} src="/images/quickbooks-2.svg" alt="quickbooks"/>
                    Quickbooks
                </Col>
            </Row>
            <br/>
            <br/>
            <br/>
            <br/>
            <h5 style={{ fontSize: isMobile ? "1.5rem" : "2rem" }}> Contact Me </h5>
            <div>
                <div>
                    <a style={{color: "black", textDecoration: isMobile ? "underline" : "none"}} href="mailto:jessie.glez96@gmail.com">Send Me an Email</a>
                </div>
                <div>
                   <a 
                        style={{color: "black", textDecoration: isMobile ? "underline" : "none"}}
                        href="https://www.linkedin.com/in/jessicagonzaleznieto/" 
                        target="_blank" rel="noopener norefferer"
                    >
                        Message Me On LinkedIn
                    </a> 
                </div>
            </div>
            <br/>
            <br/>
            <br/>
                <a href="https://www.linkedin.com/in/jessicagonzaleznieto/" target="_blank" rel="noopener norefferer">
                    <img style={{...iconStyle}} src="/images/linkedin.svg" alt="linkedIn"/>
                </a>   
                  
                <a href="https://github.com/jessicagonzaleznieto" target="_blank" rel="noopener norefferer">
                    <img style={{...iconStyle, marginLeft: "2rem"}} src="/images/github-logo.svg" alt="GitHub"/>
                </a>
            <br/>
            <br/>
            <br/>
            <br/>
        </Container>
        </Fragment>
    );
}
 
export default MainPage;
