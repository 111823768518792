import React from 'react';
import { Button } from 'react-bootstrap';
import './PillButton.css'; // Create a corresponding CSS file

const PillButton = ({ children, downloadLink, downloadName, ...props }) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = `${downloadName}`;
        link.click();
    };
    return (
        <Button className="pill-button" onClick={handleDownload} {...props}>
            {children}
        </Button>
    );
};

export default PillButton;